import request from '@/utils/request'

// 信息管理 - 问诊信息 - 问诊记录
export function interviewRecord(params) {
	return request({
		url: 'admin/therapy/chat',
		method: 'GET',
		params
	})
}

// 信息管理 - 问诊信息 - 电子处方
export function ePrescription(params) {
	return request({
		url: '/admin/pres/show/',
		method: 'GET',
		params
	})
}
// 获取诊断列表
export function getDiseases(params) {
	return request({
		url: '/admin/therapy/getDiseases/',
		method: 'GET',
		params
	})
}
//提交病历
export function saveEmrApi(data) {
	return request({
		url: '/admin/therapy/saveEmr',
		method: 'post',
		data
	})
}





